import React from 'react';
import { SvgArea } from '../shared/styled/icons';
import { AreaContainer } from './styled';

const Area = ({ width = '1.3rem', area = 'N.C.' }) => (
  <AreaContainer title="Surface">
    <SvgArea width={width} />
    <span className="text">
      {area} m<sup>2</sup>
    </span>
  </AreaContainer>
);

export default Area;

import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const GitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Gite = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spaces.lg};
  padding: ${({ theme }) => theme.spaces.md};
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  border-radius: ${({ theme }) => theme.borders.borderRadius};
  @media (min-width: 768px) {
    flex-direction: row;
  }

  .gite-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    font-size: 0.9rem;
    @media (min-width: 768px) {
      padding-left: ${({ theme }) => theme.spaces.sm};
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${({ theme }) => theme.spaces.md};
      padding-top: ${({ theme }) => theme.spaces.sm};
      padding-bottom: ${({ theme }) => theme.spaces.md};
      border-bottom: ${({ theme }) => theme.borders.border};

      @media (min-width: 500px) and (max-width: 767px) {
        justify-content: center;

        & div {
          margin-right: ${({ theme }) => theme.spaces.lg};
        }
      }

      @media (min-width: 768px) {
        padding-top: 0;
      }
    }

    &__description {
      ${({ notAvailable }) =>
        notAvailable &&
        css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      flex-grow: 1;
      margin-top: 0;
      @media (min-width: 768px) {
        margin: 0;
      }
    }

    a {
      align-self: center;
      display: block;
      width: 120px;
      line-height: 34px;
      text-align: center;
      text-decoration: none;
      color: #fff;
      background-color: ${({ theme }) => theme.colors.green};
      border-radius: ${({ theme }) => theme.borders.borderRadius};
      border: 1px solid ${({ theme }) => theme.colors.green};
      transition: color 0.3s, background-color 0.3s;
      :hover,
      :focus {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }

  .gite__image {
    flex: 1 1 50%;
    padding-right: 0;
    @media (min-width: 768px) {
      padding-right: ${({ theme }) => theme.spaces.sm};
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
`;

export const MainInfosItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
  .item {
    display: flex;
    margin-bottom: ${({ theme }) => theme.spaces.sm};
    list-style: none;
  }
  .text {
    margin-left: 0.3rem;
    margin-right: ${({ theme }) => theme.spaces.md};
  }
`;

export const SecondaryInfos = styled.section`
  display: flex;
  flex-wrap: wrap;

  section {
    flex-grow: 1;
  }
`;

export const DescriptionList = styled.ul`
  margin-top: 0;
  padding-left: ${({ theme }) => theme.spaces.md};
  list-style: '- ';
`;

import React from 'react';
import { SvgPerson } from '../shared/styled/icons';
import { CapacityContainer } from './styled';

const Capacity = ({ number = 'N.C.', width = '1.3rem', displayNumber }) => (
  <CapacityContainer title="Capacité">
    <SvgPerson style={{ width }} />
    {displayNumber && <span>{number}</span>}
  </CapacityContainer>
);

export default Capacity;

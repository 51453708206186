import React from 'react';
import { SvgBedroom } from '../shared/styled/icons';
import { BedroomContainer } from './styled';

const Bedroom = ({ number = 'N.C.', width = '1.3rem', displayNumber }) => (
  <BedroomContainer title="Nombre de chambres">
    <SvgBedroom style={{ width }} />
    {displayNumber && <span>{number}</span>}
  </BedroomContainer>
);

export default Bedroom;

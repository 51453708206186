import styled from '@emotion/styled';

export const BedroomContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 0.2rem;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { SvgEpi } from '../shared/styled/icons';
import { EpiContainer } from './styled';

const Epi = ({ number = 1, width = '1.3rem', keyNamespace }) => {
  const epis = [];
  for (let i = 0; i < number; i++) {
    epis.push(<SvgEpi key={`${keyNamespace}${i}`} style={{ width }} />);
  }
  return <EpiContainer title={`${number} épis`}>{epis}</EpiContainer>;
};

Epi.propTypes = {
  number: PropTypes.node.isRequired,
  width: PropTypes.string,
  keyNamespace: PropTypes.string.isRequired,
};

export default Epi;
